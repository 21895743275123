/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
		init: function() {
			var $body = $('body');

			$('[data-toggle="tray"]').on('click', function(){
				$body.addClass('nav-active');
			});

			$('.page-overlay').on('click', function() {
				$body.removeClass('nav-active');
			})

			// JavaScript to be fired on all pages
			$('[data-toggle="tooltip"]').tooltip();

			// var feed = new Instafeed({
			// 	get: 'tagged',
			// 	tagName: 'willpowerfitnessdevon',
			// 	clientId: 'bc96f6f54c48469eba5e220f11aee878',
			// 	resolution: 'low_resolution',
			// 	limit: 6,
			// 	template: '<a class="grid-item" href="{{link}}"><img src="{{image}}" class="img-responsive" /></a>'
			// });

			// feed.run();

			var $tesitimonials = $("#carousel-testimonial");

			$tesitimonials.carousel();

			$tesitimonials.on('slid.bs.carousel', function () {
				var $content = $(this).find('.active .carousel-content');

				$(this).height($content.height());
			});

			function initMap() {
				// Specify features and elements to define styles.
				var styleArray = [
					{
						featureType: "all",
						stylers: [
							{
								saturation: -60
							}
						]
					},
					{
					    "featureType": "all",
					    "elementType": "labels.text.fill",
					    "stylers": [
					        {
					            "color": "#ffffff"
					        }
					    ]
					},
					{
					    "featureType": "all",
					    "elementType": "labels.text.stroke",
					    "stylers": [
					        {
					            "visibility": "off"
					        }
					    ]
					},
					{
					    "featureType": "all",
					    "elementType": "labels.icon",
					    "stylers": [
					        {
					            "visibility": "off"
					        }
					    ]
					},
					{
					    "featureType": "administrative",
					    "elementType": "geometry.fill",
					    "stylers": [
					        {
					            "color": "#c9323b"
					        }
					    ]
					},
					{
					    "featureType": "administrative",
					    "elementType": "geometry.stroke",
					    "stylers": [
					        {
					            "color": "#c9323b"
					        },
					        {
					            "weight": 1.2
					        }
					    ]
					},
					{
					    "featureType": "administrative.locality",
					    "elementType": "geometry.fill",
					    "stylers": [
					        {
					            "lightness": "-1"
					        }
					    ]
					},
					{
					    "featureType": "administrative.neighborhood",
					    "elementType": "labels.text.fill",
					    "stylers": [
					        {
					            "lightness": "0"
					        },
					        {
					            "saturation": "0"
					        }
					    ]
					},
					{
					    "featureType": "administrative.neighborhood",
					    "elementType": "labels.text.stroke",
					    "stylers": [
					        {
					            "weight": "0.01"
					        }
					    ]
					},
					{
					    "featureType": "administrative.land_parcel",
					    "elementType": "labels.text.stroke",
					    "stylers": [
					        {
					            "weight": "0.01"
					        }
					    ]
					},
					{
					    "featureType": "landscape",
					    "elementType": "geometry",
					    "stylers": [
					        {
					            "color": "#c9323b"
					        }
					    ]
					},
					{
					    "featureType": "poi",
					    "elementType": "geometry",
					    "stylers": [
					        {
					            "color": "#99282f"
					        }
					    ]
					},
					{
					    "featureType": "road",
					    "elementType": "geometry.stroke",
					    "stylers": [
					        {
					            "visibility": "off"
					        }
					    ]
					},
					{
					    "featureType": "road.highway",
					    "elementType": "geometry.fill",
					    "stylers": [
					        {
					            "color": "#99282f"
					        }
					    ]
					},
					{
					    "featureType": "road.highway.controlled_access",
					    "elementType": "geometry.stroke",
					    "stylers": [
					        {
					            "color": "#99282f"
					        }
					    ]
					},
					{
					    "featureType": "road.arterial",
					    "elementType": "geometry",
					    "stylers": [
					        {
					            "color": "#99282f"
					        }
					    ]
					},
					{
					    "featureType": "road.local",
					    "elementType": "geometry",
					    "stylers": [
					        {
					            "color": "#99282f"
					        }
					    ]
					},
					{
					    "featureType": "transit",
					    "elementType": "geometry",
					    "stylers": [
					        {
					            "color": "#99282f"
					        }
					    ]
					},
					{
					    "featureType": "water",
					    "elementType": "geometry",
					    "stylers": [
					        {
					            "color": "#262626"
					        }
					    ]
					}
				];

				// Create a map object and specify the DOM element for display.
				var map = new google.maps.Map(document.getElementById('map'), {
					center: {
						lat: 50.395873,
						lng: -3.855844
					},
					scrollwheel: false,
					// Apply the map style array to the map.
					styles: styleArray,
					zoom: 11
				});

				var marker = new google.maps.Marker({
					position: {
						lat: 50.327101, lng: -3.746742
					},
					map: map,
					title: 'The willpowerfitnessbarn',
					icon: 'http://willpowerfitness.co.uk/wp/wp-content/themes/will-power-fitness-theme/dist/images/map_marker.svg'
				});
			}

			if ($('#map').length > 0) {
				initMap();
			};

		},
		finalize: function() {
			// JavaScript to be fired on all pages, after page specific JS is fired
		}
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('#main-carousel').carousel();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'contact': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
    // AIzaSyC1yfdoiQXsPOJn8I7rojyZaxj-0zYN6p4
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
